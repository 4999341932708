import { useCallback } from "react"
import { getCachedSecret, useUser } from "./use-user"
import Notification from "../components/notification"
import ButterToast from "butter-toast"
import { LikeType, Item, Channel, User } from "../types"
import React from "react"
import { showToast } from "../components/status-notification"
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import CustomNotification from "../components/custom-notification"
import { useNode } from "../hooks/use-node"

const defaultThankUMessages = [
  "Thank you!\nYou are awesome!",
  "You helped a lot!\n",
  "Amazing! Thank you!\n",
  "Cool! Thanks.",
  "Wow! That's great! Thanks.\n",
  "Thank you!",
  "Thank you!",
  "You're the man, bro.",
  "Thank you! It's so nice of you!",
  "Thanks. We love you.",
  "It's a great help. Thank you!",
  "Good like there! Thanks!",
  "Bullseye! Thanks a lot!",
  "Bingo? Smartlike! Your likes make all user’s experience better.",
  "A smokin’ smartlike!",
  "You are on fire! Thank you!",
  "What’s a like among friends? Paying up is voluntary but helps authors to create more good stuff.",
  "Keep them smartlikes rollin’!",
]

const localMessages = ["Saved", "Added to liked content", "Worth remembering"]

var demoCommentPublishMessages = [
  "Keep on writing!\nWhen people smartlike your posts you receive money.\n",
  "Amazing! Good post!\n",
  "Keep on posting clever comments and get rewarded!",
]

var demoCommentUpvoteMessages = [
  "Thank you!\nYou reward the author and help others discover the comment.\n",
  "Amazing! Good comment wins!",
  "Amazing! Good comment gets promoted!",
  "Great! Clever commentators are rewarded!",
]

var demoCommentDownvoteMessages = [
  "Let the good comments win!",
  "Thanks for helping curating discussion!",
]

const markThankuMessages = [
  "Thank you for improving crowdsourced filter!",
  "Thank you for improving crowdsourced filter!\nYou are awesome!",
  "You helped a lot!\n",
  "Amazing! Thank you!\n",
  "Cool! Thanks.",
  "You've just made the world better!",
  "Thank you!",
]

export const useLike = () => {
  const {
    user,
    accountState,
    updateBalance,
    updateItemLikes,
    updateChannelLikes,
  } = useUser()

  const { rpc } = useNode()

  const defaultNotice = (creator: string) => {
    const message =
      defaultThankUMessages[
        Math.floor(Math.random() * defaultThankUMessages.length)
      ]

    import(
      "../images/toast/" + (Math.floor(Math.random() * 8) + 1) + ".png"
    ).then(image => {
      const c = React.createElement(
        Notification,
        {
          image: image.default,
          creator: creator,
          text: message,
        },
        null
      )

      ButterToast.raise({
        content: c,
        timeout: 3000,
      })
    })
  }

  const sendLike = useCallback(
    async (
      msg: any,
      item: Item | Channel | null,
      messages: string[] | null,
      onSuccess: () => any
    ) => {
      if (msg.amount == 0) {
        if (user && user.like_value) msg.amount = user.like_value
        else msg.amount = 0.01
      }

      msg.currency = msg.currency = user.displayed_currency

      // USD amount for local storage
      let usd_amount = msg.amount
      if (user && user.displayed_currency != "USD") {
        usd_amount = msg.amount / user.displayed_currency_rate
        msg.currency = user.displayed_currency
      }

      if (accountState < 0) {
        if (item) {
          if (msg.kind == LikeType.Item) updateItemLikes(item, usd_amount)
          else if (msg.kind == LikeType.Channel) {
            if ("alias_host" in item)
              updateChannelLikes("author_likes", item, usd_amount)
            else updateChannelLikes("website_likes", item, usd_amount)
          }
        }
        const message =
          localMessages[Math.floor(Math.random() * localMessages.length)]
        import(
          "../images/toast/" + (Math.floor(Math.random() * 8) + 1) + ".png"
        ).then(image => {
          const c = React.createElement(
            Notification,
            {
              image: image.default,
              creator:
                item == null
                  ? ""
                  : msg.kind == LikeType.Item
                  ? item.publisher.url
                  : "alias_host" in item
                  ? item.title
                  : item.id,
              text: message,
            },
            null
          )

          ButterToast.raise({
            content: c,
            timeout: 3000,
          })
        })
      } else {
        let message: string = ""
        if (messages)
          message = messages[Math.floor(Math.random() * messages.length)]
        else {
          message =
            defaultThankUMessages[
              Math.floor(Math.random() * defaultThankUMessages.length)
            ]
        }

        rpc(
          "like",
          JSON.stringify(msg),
          user,
          getCachedSecret(),
          function (res) {
            //console.log(res)
            if (res.status == "ok") {
              if (item) {
                if (msg.kind == LikeType.Item) updateItemLikes(item, usd_amount)
                else if (msg.kind == LikeType.Channel) {
                  if ("alias_host" in item)
                    updateChannelLikes("author_likes", item, usd_amount)
                  else updateChannelLikes("website_likes", item, usd_amount)
                }
              }
              //if (res.data && res.data.balance) {
              updateBalance(-msg.amount)
              //item.amount += amount
              //console.log("updating item " + item.amount)
              onSuccess()
              //}
              /*
            if (res.data.length > 0) {
              message = res.data
            }*/

              let alias = ""
              if (msg.kind == LikeType.Item) {
                if (
                  item &&
                  item.creator &&
                  item.creator.title &&
                  item.publisher &&
                  item.publisher.id
                )
                  alias = item.creator.title + "#" + item.publisher.id
              }

              const creator =
                item == null
                  ? ""
                  : msg.kind == LikeType.Item
                  ? item.publisher.url
                  : "alias_host" in item
                  ? item.title
                  : item.id

              if (alias.length) {
                fetch("/api/v2/thank-u?alias=" + encodeURIComponent(alias))
                  .then(res => res.text())
                  .then(t => {
                    //console.log(t)
                    if (t.length > 0) {
                      const j = JSON.parse(t)
                      const c = React.createElement(
                        CustomNotification,
                        {
                          image: j.image,
                          creator: j.title,
                          text: j.thanks,
                        },
                        null
                      )

                      ButterToast.raise({
                        content: c,
                        timeout: 3000,
                      })
                    } else defaultNotice(creator)
                  })
                  .catch(error => {
                    defaultNotice(creator)
                  })
              } else defaultNotice(creator)
            } else {
              showToast(
                faInfoCircle,
                "Smartlike network",
                "Failed to send like"
              )
            }
          }
        )
      }
    },
    [user, accountState]
  )

  const report = useCallback(
    (
      id: string,
      group: string | undefined,
      contentType: LikeType,
      author: string,
      bit: number,
      pro: boolean,
      user: User,
      secret: string,
      setBalance: (amount: number) => void
    ) => {
      id = contentType == LikeType.Commentator ? "." + id : id
      const msg = {
        id: id,
        node_id: group,
        flag: bit,
        pro: pro,
      }

      rpc("report", JSON.stringify(msg), user, secret, function (res) {
        if (res.status == "ok") {
          setBalance(-0.01 * user.displayed_currency_rate)
          const message =
            markThankuMessages[
              Math.floor(Math.random() * markThankuMessages.length)
            ]
          import(
            "../images/toast/" + (Math.floor(Math.random() * 8) + 1) + ".png"
          ).then(image => {
            const c = React.createElement(
              Notification,
              {
                image: image.default,
                creator: author,
                text: message,
              },
              null
            )

            ButterToast.raise({
              content: c,
              timeout: 3000,
            })
          })
        } else {
          console.error("Reporting failed")
        }
      })
    },
    []
  )

  return {
    sendLike,
    defaultNotice,
    report,
  }
}
