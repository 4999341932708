import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { Helmet as Head } from "react-helmet"
import { useUser, getCachedSecret } from "../hooks/use-user"
import { useNode } from "../hooks/use-node"
import { generateMnemonic, validateMnemonic } from "../services/crypto"
import { useStyle } from "../hooks/use-style"
import { lightTheme, darkTheme } from "../services/theme"
import Notice from "../components/widgets/notice"
import { sign, getPublicKey, arr2base64 } from "../services/crypto"
import { Link } from "@reach/router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy } from "@fortawesome/pro-light-svg-icons"
import { LikeType, Channel } from "../types"
import { prepareUrl } from "../services/content"
import { unstable_batchedUpdates } from "react-dom"
import { useMedia } from "../hooks/use-media"
import { useItems } from "../hooks/use-items"
import CustomNotification from "../components/custom-notification"
import ButterToast from "butter-toast"
import { useLike } from "../hooks/use-like"

const IntegrationPage: FunctionComponent = ({ location }) => {
  const [search, setSearch] = useState(location.search)
  const [pars, setPars] = useState(new URLSearchParams())
  const { user, isLoggedIn, login1, addRepeatingDonationById } = useUser()
  const [secret, setSecret] = useState("")
  const [fillAccount, setFillAccount] = useState(false)
  const [error, setError] = useState("")
  const [backup, setBackup] = useState(false)
  const [invalidSecret, setInvalidSecret] = useState(false)
  const [donationAmount, setDonationAmount] = useState(0)
  const [mode, setMode] = useState("")
  const [comment, setComment] = useState("")
  const [state, setState] = useState("")
  const { isTouchDevice } = useMedia()
  const [authorInfo, setAuthorInfo] = useState(null)
  const { defaultNotice } = useLike()
  const { saveLike } = useItems([])
  const { rpc } = useNode()

  useEffect(() => {
    if (location && location.search && location.search.length > 2) {
      const urlSearchParams = new URLSearchParams(location.search)

      if (urlSearchParams.has("url")) {
        setDonationAmount(0.01)
        let { preparedUrl, publisher } = prepareUrl(urlSearchParams.get("url"))
        urlSearchParams.set("recipient", preparedUrl)
        //urlSearchParams.set("return_url", urlSearchParams.get("url"))
        urlSearchParams.set("currency", "USD")
        setMode("Smartlike")
        setPars(urlSearchParams)
      } else if (urlSearchParams.has("text")) {
        let text = urlSearchParams.get("text")
        if (text) {
          let start = text?.indexOf("https:")
          let end = text?.indexOf(" ", start)
          let length = end == -1 ? text.length - start : end - start
          let url = text?.substr(start, length)
          let { preparedUrl, publisher } = prepareUrl(url)
          //console.log("text " + text + " " + start + " " + end + " " + length)
          setDonationAmount(0.01)
          urlSearchParams.set("recipient", preparedUrl)
          urlSearchParams.set("hostname", publisher)
          //urlSearchParams.set("return_url", url)
          urlSearchParams.set("currency", "USD")

          setMode("Smartlike")
          setPars(urlSearchParams)
        }
      } else if (urlSearchParams.has("return_url")) {
        var tmp = document.createElement("a")
        tmp.href = urlSearchParams.get("return_url")
        if (tmp.hostname && tmp.hostname.length) {
          urlSearchParams.set("hostname", tmp.hostname)
          if (urlSearchParams.get("token")?.indexOf("{") != -1) {
            window.open(
              pars.get("return_url") +
                "?error=" +
                encodeURIComponent("token cannot contain curly braces"),
              "_self"
            )
          }
          if (
            urlSearchParams.has("currency") &&
            urlSearchParams.has("recipient")
          ) {
            if (urlSearchParams.has("suggest_amount"))
              setDonationAmount(
                parseFloat(urlSearchParams.get("suggest_amount"))
              )
            if (
              urlSearchParams.has("subscribe") &&
              urlSearchParams.has("token")
            ) {
              setMode("Subscribe")
            } else if (urlSearchParams.has("donate")) {
              setMode("Donate")
            } else {
              setMode("Smartlike")
            }
          } else if (urlSearchParams.has("token")) setMode("Login")

          setPars(urlSearchParams)
        }
      }
    }
  }, [search])

  useEffect(() => {
    if (getCachedSecret() == null || getCachedSecret()?.length == 0) return

    if (isLoggedIn() && user) {
      if (user.balance.balance > 0) {
        console.log("balance " + user.balance.balance)
        if (pars.has("subscribe")) {
          subscribe()
          //returnHome(getCachedSecret(), user.pk_base64, user.id)
        } else if (pars.has("donate")) {
          donate()
        } else {
          smartlike()
        }
      } else {
        setBackup(false)
        setInvalidSecret(false)
        setFillAccount(true)
      }
    } else {
      setFillAccount(true)
    }
  }, [user, pars])

  const returnHome = useCallback(
    (secret: string, pk: string, user_id: string) => {
      const signature = sign(pars.get("token"), secret)
      window.open(
        pars.get("return_url") +
          "?signature=" +
          encodeURIComponent(signature) +
          "&token=" +
          encodeURIComponent(pars.get("token")) +
          "&public_key=" +
          encodeURIComponent(pk) +
          (pars.has("subscribe") && user_id.length
            ? "&account=" + user_id
            : ""),
        "_self"
      )
    },
    [pars]
  )

  const isChrome =
    typeof navigator != "undefined"
      ? /Chrome/.test(navigator.userAgent) &&
        /Google Inc/.test(navigator.vendor)
      : false

  const onSubmit = useCallback(
    (explicitSecret: string | null) => {
      if (explicitSecret) {
        setSecret(explicitSecret)
      } else {
        explicitSecret = secret
      }

      if (explicitSecret.length == 0) {
        return
      }
      if (!validateMnemonic(explicitSecret)) {
        setBackup(false)
        setFillAccount(false)
        setInvalidSecret(true)
        return
      } else {
        setInvalidSecret(false)
      }

      if (
        !pars.has("suggest_amount") &&
        !pars.has("text") &&
        !pars.has("url")
      ) {
        const [pk, pkHex] = getPublicKey(explicitSecret)
        returnHome(explicitSecret, arr2base64(pk), "")
      } else {
        if (donationAmount == 0) return

        login1(explicitSecret, true, null).then(res => {})
      }
    },
    [secret, user, setInvalidSecret, donationAmount]
  )

  const generateKey = useCallback(() => {
    generateMnemonic().then(secret => {
      setSecret(secret)
      if (isChrome == false)
        document.getElementById("password-init").value = secret
      else navigator.clipboard.writeText(secret)
      document.getElementById("create-btn").innerHTML =
        "regenerate a new password"
      setInvalidSecret(false)
      setFillAccount(false)
      setBackup(true)
    })
  }, [])

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    inputBackground:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    inputBorderColor:
      theme === "light" ? lightTheme.color.border : "transparent",
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    activeButtonBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
  }))

  const onAmountChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length == 0) {
      setDonationAmount(0)
      return
    } else {
      let amount = parseFloat(event.currentTarget.value)
      if (amount > 0) {
        setDonationAmount(amount)
      } else {
        setDonationAmount(0)
      }
    }
  }, [])

  const donate = useCallback(() => {
    const msg = {
      kind: LikeType.DonateChannel,
      target: pars.get("recipient"),
      amount: donationAmount,
      currency: pars.get("currency"),
      payload: JSON.stringify({
        action: "",
        text: comment,
      }),
    }
    rpc("like", JSON.stringify(msg), user, getCachedSecret(), function (res) {
      console.log(res)
      if (res.status == "ok") {
        window.open(pars.get("return_url") + "?status=success", "_self")
      } else {
        if (res.status == "error") {
          if (res.data == "insufficient funds") {
            setFillAccount(true)
          } else setError(res.data)
        }
      }
    })
  }, [user, pars, donationAmount, comment])

  const smartlike = useCallback(() => {
    const msg = {
      kind: LikeType.Item,
      target: pars.get("recipient"),
      amount: donationAmount,
      currency: pars.get("currency"),
      payload: "",
    }
    rpc("like", JSON.stringify(msg), user, getCachedSecret(), function (res) {
      console.log(res)
      if (res.status == "ok") {
        saveLike(pars.get("recipient"), donationAmount, pars.get("currency"))
        if (pars.has("return_url")) {
          window.open(pars.get("return_url") + "?status=success", "_self")
        } else {
          fetch(
            "/api/v2/thank-u?url=" + encodeURIComponent(pars.get("recipient"))
          )
            .then(res => res.text())
            .then(t => {
              console.log(t)
              if (t.length > 0) {
                const j = JSON.parse(t)

                const c = React.createElement(
                  CustomNotification,
                  {
                    image: j.image,
                    creator: j.title,
                    text: j.thanks,
                  },
                  null
                )

                ButterToast.raise({
                  content: c,
                  timeout: 2000,
                })

                //setAuthorInfo(j)
              } else {
                defaultNotice(pars.get("hostname"))
              }
              setTimeout(function () {
                window.close()
              }, 2000)
            })
            .catch(error => {
              defaultNotice(pars.get("hostname"))
              setTimeout(function () {
                window.close()
              }, 2000)
              return null
            })
        }
      } else {
        if (res.status == "error") {
          if (res.data == "insufficient funds") {
            setFillAccount(true)
          } else setError(res.data)
        }
      }
    })
  }, [user, pars, donationAmount])

  const subscribe = useCallback(() => {
    if (pars.has("recipient") && user) {
      let subscription = {
        recipient: pars.get("recipient"),
        amount: donationAmount,
        currency: pars.get("currency"),
        title: user && user.profile ? user.profile.title : "",
        avatar: user && user.profile ? user.profile.image : "",
        comment: comment,
      }

      rpc(
        "add_recurring_donation",
        JSON.stringify(subscription),
        user,
        getCachedSecret(),
        function (res) {
          if (res.status == "ok") {
            setState("subscribed")
            addRepeatingDonationById(pars.get("recipient"), donationAmount, 1)
          } else {
            if (res.status == "error") {
              if (res.data == "insufficient funds") {
                setFillAccount(true)
              } else setError(res.data)
            }
          }
        }
      )
    }
  }, [user?.id, pars, donationAmount])

  useEffect(() => {
    if (user && pars.has("recipient")) {
      if (state == "subscribed") {
        for (var i = 0; i < user.subscriptions.length; i++) {
          if (
            pars.get("recipient") == user?.subscriptions[i].id &&
            user?.subscriptions[i].regularSupport
          ) {
            returnHome(getCachedSecret(), user.pk_base64, user.id)
          }
        }
      }
    }
  }, [user?.subscriptions, state])

  const onCommentChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setComment(event.currentTarget.value)
    },
    [comment]
  )

  const onSliderChange = useCallback(e => {
    //console.log(value)
    setDonationAmount(parseFloat(e.target.value))
  }, [])

  const onFormSubmit = useCallback(e => {
    e.preventDefault()
    return false
  }, [])

  const onChange = useCallback(event => {
    //console.log("onChange")
    setSecret(event.target.value)
  }, [])

  const onInput = useCallback(
    event => {
      //console.log("onInput " + event.inputType + " " + event.data)
      if ("insertReplacementText" === event.inputType || !("data" in event)) {
        console.log("auto-fill 2 " + event.target.value)
        //onSubmit(event.target.value)
      }
    },
    [secret]
  )

  const onAnimationStart = useCallback(
    event => {
      //console.log("onAnimationStart " + event.animationName)
      if ("onautofillstart" === event.animationName) {
        //console.log("auto-fill 1")
        onSubmit(event.target.value)
      }
    },
    [secret]
  )

  return (
    <>
      <Head>
        <title>Smartlike</title>
        <meta name="description" content="Smartlike p2p donations" />
        <meta property="og:title" content="Smartlike" />
        <meta property="og:description" content="Smartlike p2p donations" />
      </Head>

      <div
        style={{
          fontFamily: "Roboto",
          marginBottom: "20px",
        }}
      >
        <div className={isTouchDevice ? "m-wrapper" : "wrapper"}>
          <>
            {mode == "Donate" && (
              <div style={{ textAlign: "center" }}>
                <div className="header">Donate to {pars.get("recipient")}</div>
                <div
                  style={{
                    display: "inline-flex",
                    marginTop: "10px",
                    width: "100%",
                    marginBottom: "15px",
                  }}
                >
                  <div className="amountCell">
                    <input
                      type="hidden"
                      id="username"
                      name="username"
                      value=""
                      autoComplete="username"
                    />

                    <input
                      id="pledge"
                      type="number"
                      step="0.01"
                      value={donationAmount}
                      onChange={onAmountChange}
                      className="amount"
                      autoComplete="never"
                    />
                  </div>
                  <div style={{ margin: "auto" }}>{pars.get("currency")}</div>
                  <input
                    type="text"
                    value={comment}
                    onChange={onCommentChange}
                    className="amount"
                    placeholder="optional comment"
                    onFocus={e => {
                      e.currentTarget.removeAttribute("readonly")
                    }}
                    readOnly
                    style={{
                      width: "100%",
                      marginLeft: "10px",
                      textAlign: "left",
                    }}
                  />
                </div>
              </div>
            )}
            {mode == "Smartlike" && (
              <div style={{ textAlign: "center" }}>
                <div className="header">
                  {pars.has("title")
                    ? pars.get("title")
                    : pars.get("recipient")}
                </div>

                <form onSubmit={onFormSubmit} autoComplete="off">
                  <div
                    style={{
                      display: "inline-flex",
                      marginTop: "10px",
                      marginBottom: "15px",
                    }}
                  >
                    <div className="amountCell">
                      <input
                        type="hidden"
                        id="username"
                        name="username"
                        value=""
                        autoComplete="username"
                      />
                      <input
                        id="pledge"
                        type="number"
                        step="0.01"
                        value={donationAmount}
                        onChange={onAmountChange}
                        className="amount"
                        autoComplete="never"
                      />
                    </div>
                    <div style={{ margin: "auto" }}>{pars.get("currency")}</div>
                  </div>
                </form>

                <div className="slidercontainer">
                  <input
                    type="range"
                    value={donationAmount}
                    onChange={onSliderChange}
                    min={0.01}
                    max={5}
                    step={0.01}
                  />
                </div>
              </div>
            )}
            {mode == "Subscribe" && (
              <div style={{ textAlign: "center" }}>
                <div className="header">
                  <div style={{ margin: "auto" }}>
                    Subscribe to {pars.get("recipient")} with {donationAmount}{" "}
                    {pars.get("currency")} a month
                  </div>
                </div>
              </div>
            )}
            {mode == "Login" && (
              <div className="header">
                You are about to log in to {pars.get("hostname")}
              </div>
            )}
            <div
              style={{
                marginBottom: "20px",
                lineHeight: "22px",
                textAlign: "justify",
              }}
            >
              <div>
                Please pick out an identity saved in your password manager or
                generate and enter a new one:
              </div>
              <input
                id="password-init"
                className="text-input"
                type="password"
                name="account_key"
                ref={input => {}}
                onChange={onChange}
                onAnimationStart={onAnimationStart}
              />

              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <button
                  style={{
                    padding: "5px",
                    width: "100px",
                    marginTop: "30px",
                    marginBottom: "10px",
                  }}
                  id="password-init-submit"
                  type="submit"
                  onClick={() => onSubmit(null)}
                  disabled={secret.length == 0}
                >
                  {mode}
                </button>

                <div id="create-btn" onClick={generateKey} className="generate">
                  generate new password
                </div>

                <div
                  style={{
                    margin: "auto",
                    marginBottom: "25px",
                  }}
                >
                  <div
                    style={{
                      marginTop: "3px",
                      fontFamily: "Roboto",
                      height: "70px",
                    }}
                  >
                    {invalidSecret && (
                      <div
                        style={{
                          color: "red",
                          fontFamily: "Roboto",
                        }}
                      >
                        Invalid secret. Please use a generated one.
                      </div>
                    )}

                    {fillAccount && (
                      <div style={{ textAlign: "left" }}>
                        There's not enough funds on the account.
                        <br />
                        <Link to={"/donate"} style={{}}>
                          Fill the account
                        </Link>
                      </div>
                    )}

                    {error && <div style={{ textAlign: "left" }}>{error}</div>}

                    {backup == true && (
                      <>
                        <div
                          style={{
                            fontFamily: "Roboto",
                            textAlign: "left",
                          }}
                        >
                          {isChrome ? (
                            <>
                              The below key has been copied to clipboard, paste
                              it in the box above and save it in your password
                              manager:
                            </>
                          ) : (
                            <>
                              Please keep your account key in the password
                              manager and make a back up:
                            </>
                          )}
                        </div>
                        <div
                          style={{
                            fontSize: "14px",
                            marginTop: "3px",
                          }}
                        >
                          <code>{secret}</code>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {mode == "Login" && (
                <Notice id="login_info" submit="Got it">
                  <p>Your privacy guarantees:</p>
                  <ul style={{ paddingInlineStart: "20px" }}>
                    <li>
                      the operation is not tracked by any other third party
                      including smartlike.org.
                    </li>
                    <li>
                      <b>{pars.get("hostname")}</b> will only know your
                      Smartlike account. If you have not attached it to your
                      social network accounts or any other private data, the
                      session will be <b>anonymous</b>. You may want to consider
                      using separate accounts for sensitive login sessions.
                    </li>
                  </ul>
                </Notice>
              )}
              {mode == "Donate" && (
                <Notice id="donate_info" submit="Got it">
                  <p>
                    The transaction is published on Smartlike network. Here are
                    your privacy options:
                  </p>
                  <ul style={{ paddingInlineStart: "20px" }}>
                    <li>
                      you choose a trusted way to fill your Smartlike account
                    </li>
                    <li>
                      you control whether your Smartlike account is connected to
                      to your social network accounts or any other private data
                      and can use separate accounts for sensitive transactions.
                    </li>
                  </ul>
                </Notice>
              )}
              {mode == "Subscribe" && (
                <Notice id="subscribe_info" submit="Got it">
                  <p>Subscription processing details:</p>
                  <ul style={{ paddingInlineStart: "20px" }}>
                    <li>first payment is billed on subscription creation</li>
                    <li>next payments are billed in one month period</li>
                    <li>
                      subscription can be cancelled anytime on smartlike.org
                    </li>
                  </ul>
                </Notice>
              )}
            </div>
          </>
        </div>
      </div>
      <style jsx>{`
        .wrapper {
          padding: 30px;
          max-width: 700px;
          width: 100%;
          margin: auto;
          background-color: white;
          margin-top: 30px;
          border-radius: 5px;
        }
        .m-wrapper {
          padding: 30px;
          max-width: 700px;
          width: 100%;
          margin: auto;
          background-color: wheat;
        }

        input[type="range"] {
          color: grey;
          width: 100%; /* Specific width is required for Firefox. */
        }

        .form {
          margin: 0 auto;
          padding: 100px 30px 0;
        }

        .input-range {
          margin-bottom: 160px;
        }
        .slidercontainer {
          width: 100%; /* Width of the outside container */
          margin-top: 20px;
          margin-bottom: 40px;
        }
        .slider {
          width: 100px;
        }
        .submitCell {
          width: 100%;
          margin-bottom: 20px;
        }
        .submit {
          width: 90px;
          font-family: "Roboto";
          padding: 2px;

          color: inherit;
          background-color: transparent;
          border: 1px solid ${css.borderColor};
          padding: 5px;
          transition: 200ms border-color, 200ms background-color, 200ms color;
          z-index: 1;
          border-radius: 0.3em;
          background-color: ${css.activeButtonBackgroundColor};
        }
        .submit:hover {
          box-shadow: 0 0 0 1px ${css.focusColor};
        }

        .amountCell {
          display: table-cell;
          vertical-align: top;
          padding-right: 5px;
          width: 100px;
        }
        .amount {
          width: 100%;
          text-align: right;
          font-family: "Roboto";
          font-size: 14px;
          background-color: ${css.backgroundColor};
          border: 1px solid ${css.inputBorderColor};
          transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
          border-radius: 0.4em;
          padding: 5px;
          resize: vertical;
          width: 100%;
          color: inherit;
          /*min-height: 3.3em;*/
          line-height: 18px;
        }
        .amount:focus,
        .amount:hover {
          box-shadow: 0 0 0 1px ${css.focusColor};
        }

        .header {
          text-align: center;
          font-size: 17px;
          margin-bottom: 20px;
          margin-top: 20px;
          font-weight: 400;
        }

        .donate-header {
          display: inline-flex;
          text-align: center;
          font-size: 17px;
          margin-bottom: 40px;
          margin-top: 20px;
        }

        .generate {
          cursor: pointer;
          /*display: inline;
            margin-left: 20px;*/
          text-decoration: underline;
          /*margin-bottom: 20px;*/
          outline: none;
          user-select: none;
          text-align: left;
        }
        .custom-form {
          margin-top: 30px;
        }

        .text-input {
          width: 100%;
          height: 100%;
          margin: auto;
          background-color: ${css.backgroundColor};
          border: 1px solid ${css.inputBorderColor};
          transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
          border-radius: 0.4em;
          color: inherit;
          padding-left: 0.5em;
          padding-right: 0.5em;
          flex: 1;
          font: inherit;
          outline: none;
          height: 32px;
        }
        .text-input:focus,
        .text-input:hover {
          box-shadow: 0 0 0 1px ${css.focusColor};
        }
        ::-webkit-input-placeholder {
          /* Edge */
          opacity: 0.5;
        }
        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          opacity: 0.5;
        }
        ::placeholder {
          opacity: 0.5;
        }
      `}</style>
    </>
  )
}
export default IntegrationPage
